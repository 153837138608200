html {
  overflow-y: scroll;
}

.App {
  font-family: "Open Sans", sans-serif;
  /* the height here is a hack to avoid a conflict between overflow-y: scroll to prevent page jumping from the colapse element and a jump the modal causes due to the overflow setting */
  height: 91vh;
}

.allBtn:hover {
  background-color: #617fa1;
}

.underline:hover {
  color: blue;
  text-decoration: underline;
}

.pointer:hover {
  cursor: pointer;
}

/* Login */

.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.loginChildLogin {
  width: 400px;
}

.loginBtn {
  background-color: #4d7898;
}

.loginBtn:hover {
  background-color: #617fa1;
}

.signInTxt {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 5px;
}

.loginAlertDiv {
  height: 10vh;
}

/* Loading */

.loadingChild {
  display: flex;
  justify-content: center;
}

/* Nav */

.bigNavContainer {
  display: flex;
  flex-direction: column;
  background: white;
  height: 10vh;
  margin-left: 8%;
  margin-right: 8%;
}

.navTopRow {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.navChildLogo {
  align-self: center;
  width: 30%;
}

.navRightItems {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.profilebtn {
  margin-right: 1px;
}

/* Dashboard */
.dashCondRenderDiv {
  padding-top: 100px;
}

.truckIconAlign {
  vertical-align: bottom;
  color: #617fa1;
}

.lastUpdatedDashboard {
  vertical-align: bottom;
  font-size: 0.8em;
  color: darkgrey;
}

.bold {
  font-weight: bold;
}

/* Search */
.searchContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.searchSelect {
  align-self: center;
  display: flex;
  justify-content: flex-end;
}

.searchBtnPad {
  padding-left: 20px;
}

.searchingParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
}

.searchingIco {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
  width: 100%;
}

.searchingTxt {
  display: flex;
  justify-content: center;
}

/* Catagory Enabled / Disabled */

.catagoryDivEnabled {
  color: #333333;
  border-style: solid;
  border-color: #6692b2;
  border-width: 2px;
  border-radius: 5px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 7px;
  padding-bottom: 7px;
  margin-top: 10px;
}

.catagoryDivDisabled {
  background-color: whitesmoke;
  color: #ababab;
  border-style: solid;
  border-color: #6692b2;
  border-width: 2px;
  border-radius: 5px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 7px;
  padding-bottom: 7px;
  margin-top: 10px;
}

/* Shipment Card */

.condRenderDetailBodyAndFormText {
  color: grey;
  text-align: center;
}

.hilight {
  background-color: #ffff99 !important;
  padding: 5px;
  /* round corners */
  border-radius: 10px;
  /* darker yellow border */
  border: 1px solid #e6e600;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Checkbox stuff due to weirdness / bug with boostrap's checkbox sizes */

.test {
  width: 100%;
  height: 100%;
}
.custom-control-label::before,
.custom-control-label::after {
  top: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
}

.checkShipmentBoxHide {
  display: none;
  height: 100%;
  width: 100%;
}

.checkShipmentBoxShow {
  display: unset;
  height: 100%;
  width: 100%;
}

.cardHeadderRow {
  font-size: 1em;
}

.cardBodyRow1 {
  font-size: 1.2em;
}

.cardBodyRow2 {
  font-size: 0.9em;
}

.statusButtons {
  margin-right: 5px;
}

.msgAndDocButtons {
  margin-left: 5px;
  margin-top: 5px;
}

.whiteBackgroundBtn {
  background-color: white;
}

.statusButtons:hover {
  background-color: #617fa1;
}

.statusButtons:active {
  background-color: #617fa1 !important;
}

.viewDocsMsgRow {
  margin-top: 10px;
}
/* Hides the default button that comes with file input in favor of the bootstrap btn */
input[type="file"] {
  display: none;
}

.shipUpdateAlertPad {
  height: 50px;
}

.messageDiv {
  border-style: solid;
  border-color: #6692b2;
  border-width: 2px;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
}

.shipCardStatusDescription {
  font-size: 0.8em;
}

/* .colorDividerTop {
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #333333;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.colorDividerBottom {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #333333;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
} */

.whiteDivider {
  padding: 5px;
  margin-bottom: 10px;
}

.lightGreyDivider {
  background-color: #eeeeee;
  padding: 5px;
  margin-bottom: 10px;
}

/* Forces scrollbar in modal body */
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

/* General use class for hiding */
.hide {
  display: none;
}

.show {
}

.thinSolidBorder {
  border: thin solid;
}

.mediumSolidBorder {
  border: medium solid;
}

.textLikeAHyperLink{
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}

.xxxx {
  color:#4c8bf5 
}

label {
  margin-bottom: 0px !important;
}


/* Bounce Truck */

.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  -webkit-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
}

@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
